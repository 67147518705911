import React from "react";
import "./Skills.css";
import aws from "../../assets/icons/aws.svg";
import css3 from "../../assets/icons/css3.svg";
import bootstrap from "../../assets/icons/bootstrap.svg";
import figma from "../../assets/icons/figma.svg";
import github from "../../assets/icons/github.svg";
import html5 from "../../assets/icons/html5.svg";
import nodeJs from "../../assets/icons/node-js.svg";
import python from "../../assets/icons/python.svg";
import reactIcon from "../../assets/icons/react.svg";
import js from "../../assets/icons/square-js.svg";
import trello from "../../assets/icons/trello.svg";
import sql from "../../assets/icons/sql.svg";
import mongodb from "../../assets/icons/mongodb.svg";
import json from "../../assets/icons/json.svg";
import ejs from "../../assets/icons/ejs.svg";
import cplusplus from "../../assets/icons/cplusplus.svg";
import express from "../../assets/icons/express.svg";
import django from "../../assets/icons/django.svg";
import jquery from "../../assets/icons/jquery.svg";
import heroku from "../../assets/icons/heroku.svg";
import postgresql from "../../assets/icons/postgresql.svg";

const Skills = () => {
  return (
    <div className="skills-container" id="skills">
      <h2>Skills</h2>
      <div className="icon-grid">
        <div className="icon-container">
          <img src={trello} alt="Trello Icon" className="icon" />
          <span className="icon-name">Trello</span>
        </div>
        <div className="icon-container">
          <img src={reactIcon} alt="React Icon" className="icon" />
          <span className="icon-name">React</span>
        </div>
        <div className="icon-container">
          <img src={github} alt="GitHub Icon" className="icon" />
          <span className="icon-name">GitHub</span>
        </div>
        <div className="icon-container">
          <img src={bootstrap} alt="bootstrap Icon" className="icon" />
          <span className="icon-name">Bootstrap</span>
        </div>{" "}
        <div className="icon-container">
          <img src={figma} alt="figma Icon" className="icon" />
          <span className="icon-name">figma</span>
        </div>{" "}
        <div className="icon-container">
          <img src={nodeJs} alt="nodeJs Icon" className="icon" />
          <span className="icon-name">Node.js</span>
        </div>{" "}
        <div className="icon-container">
          <img src={python} alt="Python Icon" className="icon" />
          <span className="icon-name">Python</span>
        </div>{" "}
        <div className="icon-container">
          <img src={js} alt="Js Icon" className="icon" />
          <span className="icon-name">JavaScript</span>
        </div>
        <div className="icon-container">
          <img src={aws} alt="AWS Icon" className="icon" />
          <span className="icon-name">AWS</span>
        </div>
        <div className="icon-container">
          <img src={css3} alt="CSS3 Icon" className="icon" />
          <span className="icon-name">CSS3</span>
        </div>
        <div className="icon-container">
          <img src={html5} alt="HTML5 Icon" className="icon" />
          <span className="icon-name">HTML5</span>
        </div>
        <div className="icon-container">
          <img src={sql} alt="sql Icon" className="icon" />
          <span className="icon-name">SQL</span>
        </div>
        <div className="icon-container">
          <img src={mongodb} alt="mongodb Icon" className="icon" />
          <span className="icon-name">MongoDB</span>
        </div>
        <div className="icon-container">
          <img src={json} alt="json Icon" className="icon" />
          <span className="icon-name">JSON</span>
        </div>
        <div className="icon-container">
          <img src={ejs} alt="ejs Icon" className="icon" />
          <span className="icon-name">EJS</span>
        </div>
        <div className="icon-container">
          <img src={cplusplus} alt="cplusplus Icon" className="icon" />
          <span className="icon-name">C++</span>
        </div>
        <div className="icon-container">
          <img src={express} alt="express Icon" className="icon" />
          <span className="icon-name">Express.js</span>
        </div>
        <div className="icon-container">
          <img src={django} alt="django Icon" className="icon" />
          <span className="icon-name">Django</span>
        </div>
        <div className="icon-container">
          <img src={jquery} alt="jquery Icon" className="icon" />
          <span className="icon-name">jQuery</span>
        </div>
        <div className="icon-container">
          <img src={heroku} alt="heroku Icon" className="icon" />
          <span className="icon-name">Heroku</span>
        </div>
        <div className="icon-container">
          <img src={postgresql} alt="postgresql Icon" className="icon" />
          <span className="icon-name">PostgreSQL</span>
        </div>
      </div>
    </div>
  );
};

export default Skills;

import React from "react";
import "./Services.css";

const Services = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    contactSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="services-container" id="services">
      <h2>Services</h2>

      <div className="service-category">
        <h3>Web Development</h3>
        <p>
          Offering modern and responsive website development using HTML, CSS,
          JavaScript, and popular frameworks like React, or any other front-end
          libraries and tools.
        </p>
      </div>

      <div className="service-category">
        <h3>Front-end Development</h3>
        <p>
          Creating interactive and user-friendly interfaces using React,
          JavaScript, HTML, and CSS to deliver high-quality front-end solutions.
        </p>
      </div>

      <div className="service-category">
        <h3>Back-end Development</h3>
        <p>
          Building robust server-side applications using Node.js, Express, and
          working with databases like MongoDB, MySQL, or other related
          technologies.
        </p>
      </div>

      <div className="service-category">
        <h3>Full-stack Development</h3>
        <p>
          Developing end-to-end applications combining front-end and back-end
          technologies to deliver comprehensive solutions.
        </p>
      </div>

      <div className="cta">
        <p style={{ marginBottom: "20px" }}>
          Interested in any of Sarah Ganz's services? <br />
          Scroll down to contact her!
        </p>
        <div className="arrow-down" onClick={scrollToContact}>
          <span className="arrow">&#8595;</span>
        </div>
      </div>
    </div>
  );
};

export default Services;

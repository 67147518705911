// Footer.js

import React from "react";
import "./Footer.css"; // Import your Footer component styles if applicable

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get current year dynamically

  return (
    <footer className="footer-container">
      {" "}
      <p>&copy; {currentYear} Sarah Ganz. All rights reserved.</p>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import "./Projects.css";
import GitHubIcon from "../../assets/icons/github.svg";
import PlayIcon from "../../assets/icons/external-link.svg"; // Replace this import with your Play icon
import mastermind from "../../assets/photos/mastermind.png";
import wishease from "../../assets/photos/wishease.png";
import syncedin from "../../assets/photos/SyncedIn.png";
import jobjog from "../../assets/photos/jobjog-logo.png";

const Projects = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  const projects = [
    {
      title: "Mastermind Solo",
      description:
        "Mastermind Solo is a digital rendition of the classic code-breaking game designed for solo play. It challenges players to crack a computer-generated color combination within a 60-second timer.",
      technologies: ["JavaScript", "CSS", "HTML"],
      githubLink: "https://github.com/sarahganz/Mastermind",
      liveDemoLink: "https://sarahganz.github.io/Mastermind/",
      imageSrc: mastermind,
    },
    {
      title: "SyncedIn",
      description:
        "SyncedIn is a full-stack application that facilitates job matching by comparing skill sets between seekers and opportunities, aiding in seamless connections between job seekers and employers.",
      technologies: [
        "Bootstrap",
        "Express",
        "Node.js",
        "MongoDB",
        "Mongoose",
        "OpenAI",
        "Heroku",
      ],
      githubLink: "https://github.com/kenneychan/SyncedIn",
      liveDemoLink: "https://syncedin2-d40349e12dd2.herokuapp.com/",
      imageSrc: syncedin,
    },

    {
      title: "WishEase",
      description:
        "WishEase enables users to create and manage a travel diary, documenting their experiences through photos and notes. Capture favorite moments, destinations, and adventures, reliving them at any time.",
      technologies: [
        "React.js",
        "Express",
        "Node.js",
        "MongoDB",
        "Bootstrap",
        "Heroku",
      ],
      githubLink: "https://github.com/sarahganz/WishEase",
      liveDemoLink: "https://wishease-edf811998b26.herokuapp.com/",
      imageSrc: wishease,
    },
    {
      title: "JobJog",
      description:
        "JobJog is a user-friendly platform designed to streamline company operations by efficiently managing employee assignments and responsibilities. It simplifies adding new employees, assigning work duties, and tracking workforce performance.",
      technologies: [
        "Python",
        "Django",
        "JavaScript",
        "HTML",
        "CSS",
        "Amazon S3 Buckets",
        "Heroku",
        "Neon",
      ],
      githubLink: "https://github.com/sarahganz/JobJog",
      liveDemoLink: "https://jobjog-fd80191af9f7.herokuapp.com/",
      imageSrc: jobjog,
    },
  ];

  const handleCardHover = (index) => {
    setHoveredCard(index);
  };

  const handleCardClick = (index) => {
    if (selectedCard === index) {
      setSelectedCard(null); // Deselect if already selected
    } else {
      setSelectedCard(index);
    }
  };

  return (
    <div className="projects-container" id="work">
      <h2>Projects Portfolio</h2>
      <div className="projects-list">
        {projects.map((project, index) => (
          <div
            className="project-card"
            key={index}
            onMouseEnter={() => handleCardHover(index)}
            onMouseLeave={() => handleCardHover(null)}
            onClick={() => handleCardClick(index)}
          >
            {(hoveredCard === index || selectedCard === index) && (
              <div className="hover-content">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
                <div id="tech">{project.technologies.join(" | ")}</div>
                <div className="project-links">
                  <a
                    href={project.githubLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={GitHubIcon} alt="GitHub Icon" />
                  </a>
                  <a
                    href={project.liveDemoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={PlayIcon} alt="Play Icon" />
                  </a>
                </div>
              </div>
            )}
            <img src={project.imageSrc} alt={project.title} />
            <h3>{project.title}</h3>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default Projects;

import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Landing from "./Components/Landing/Landing";
import About from "./Components/About/About";
import Skills from "./Components/Skills/Skills";
import Projects from "./Components/Projects/Projects";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Services from "./Components/Services/Services";
import { Routes, Route } from "react-router-dom";

// Import other components for Routes here

function App() {
  return (
    <div className="App">
      <Navbar />
      <Landing />
      <About />
      <Skills />
      <Projects />
      <Services />
      <Contact />
      <Footer />
      <Routes>
        {/* Define your routes here using <Route> components */}
        {/* Example: <Route path="/about" element={<About />} /> */}
      </Routes>
    </div>
  );
}

export default App;

import React from "react";
import "./Contact.css";
import MailIcon from "../../assets/icons/email-white.svg";
import LinkedInIcon from "../../assets/icons/linkedin-white.svg";
// import GitHubIcon from "../../assets/icons/github.svg";
import PhoneIcon from "../../assets/icons/phone-white.svg";

const Contact = () => {
  return (
    <div className="contact-container" id="contact">
      <h2>Contact</h2>
      <h3>Feel free to reach out—Sarah is just a click away.</h3>
      <div className="contact-icons">
        <div className="contact-method">
          <a
            href="mailto:sarahganzdev@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={MailIcon} alt="Email Icon" className="icon" />
          </a>
          <div className="icon-container">
            <h4 className="icon-name-it"> Email</h4>
            <a
              href="mailto:sarahganzdev@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="no-underline">sarahganzdev@gmail.com</p>
            </a>
          </div>
        </div>

        <div className="contact-method">
          <a href="tel:+19086711519">
            <img src={PhoneIcon} alt="Phone Icon" className="icon" />
          </a>
          <div className="icon-container">
            <h4 className="icon-name-it"> Phone</h4>
            <a href="tel:+19086711519">
              <p className="no-underline">(908) 671-1519</p>
            </a>
          </div>
        </div>

        <div className="contact-method">
          <a
            href="https://www.linkedin.com/in/sarahganz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedInIcon} alt="LinkedIn Icon" className="icon" />
          </a>
          <div className="icon-container">
            <h4 className="icon-name-it"> LinkedIn</h4>
            <a
              href="https://www.linkedin.com/in/sarahganz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="no-underline">linkedin.com/in/sarahganz</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import "./Landing.css";
import BackgroundVideo from "../../assets/videos/background-landing-white.mp4";

const Landing = () => {
  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: 0,
    });
  };
  return (
    <div className="landing-comp" id="landing">
      <video autoPlay muted loop className="video-background">
        <source src={BackgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="landing-content">
        <h1>Software Developer</h1>
        <h2>Transforming Ideas Into Code</h2>
        <Link
          to="/services"
          className="my-link btn-hov"
          onClick={() => scrollToSection("services")}
        >
          EXPLORE SERVICES
        </Link>
        <Link
          to="/contact"
          className="my-link btn-hov"
          onClick={() => scrollToSection("contact")}
        >
          HIRE AN EXPERT
        </Link>
      </div>
    </div>
  );
};

export default Landing;

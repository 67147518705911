import React from "react";
import "./About.css";
import { Link } from "react-router-dom";
import GitHubIcon from "../../assets/icons/github-white.svg";

import SarahPic from "../../assets/photos/sarah-pic.png";

const About = () => {
  return (
    <div className="about-container" id="about">
      <div className="text">
        <h2>About Sarah Ganz</h2>
        <p>
          Sarah Ganz is a dedicated and passionate Full Stack Developer
          committed to delivering innovative software solutions. With a robust
          background in computer science and software engineering, complemented
          by extensive experience in sales management and medical assistance,
          she brings a diverse and well-rounded skill set to every project.
        </p>
        <p>
          Sarah's commitment to crafting user-centric applications stems from
          her belief that technology should not only solve problems but also
          enhance user experiences. She thrives in transforming complex ideas
          into practical solutions, leveraging adaptability and strong
          problem-solving abilities.
        </p>
        <p>
          Specializing in developing applications that address real-world
          challenges, Sarah excels in collaborating with forward-thinking teams
          to build intuitive, efficient, and impactful software. She is
          dedicated to providing high-quality solutions and is always
          enthusiastic about embracing new challenges.
        </p>
        <p>
          Connect with Sarah to explore how her expertise can contribute to your
          projects. She is passionate about leveraging technology to create
          meaningful solutions and is always excited about new opportunities.
        </p>
        <Link
          to="https://github.com/sarahganz"
          target="_blank"
          rel="noopener noreferrer"
          className="hello"
        >
          <span className="github-content">
            <img src={GitHubIcon} alt="GitHub Icon" className="github-icon" />
            GitHub
          </span>
        </Link>
      </div>
      <div className="image">
        <img src={SarahPic} alt="Sarah Ganz" className="image" />
      </div>
    </div>
  );
};

export default About;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { scroller } from "react-scroll";
import "./Navbar.css";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState(""); // State to track active link
  const [showNav, setShowNav] = useState(false); // State to toggle navbar on smaller screens
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const landingSection = document.getElementById("landing").offsetTop;
      const logoElement = document.querySelector(".logo-absolute");
      if (scrollPosition <= landingSection) {
        logoElement.classList.remove("hidden");
      } else {
        logoElement.classList.add("hidden");
      }
      const aboutSection = document.getElementById("about").offsetTop - 100;
      const skillsSection = document.getElementById("skills").offsetTop - 100;
      const workSection = document.getElementById("work").offsetTop - 100;
      const servicesSection =
        document.getElementById("services").offsetTop - 100;
      const contactSection = document.getElementById("contact").offsetTop - 100;
      if (scrollPosition < aboutSection) {
        setActiveLink("");
      } else if (
        scrollPosition < skillsSection &&
        scrollPosition >= aboutSection
      ) {
        setActiveLink("about");
      } else if (
        scrollPosition >= skillsSection &&
        scrollPosition < workSection
      ) {
        setActiveLink("skills");
      } else if (
        scrollPosition >= workSection &&
        scrollPosition < servicesSection
      ) {
        setActiveLink("work");
      } else if (
        scrollPosition >= servicesSection &&
        scrollPosition < contactSection
      ) {
        setActiveLink("services");
      } else if (scrollPosition >= contactSection) {
        setActiveLink("contact");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: 0,
    });
  };

  return (
    <nav className={`navbar ${showNav ? "show" : "hide"}`}>
      <div
        className={`navbar-toggle ${showNav ? "close" : ""}`}
        onClick={toggleNav}
      >
        {showNav ? "✕" : "☰"}
      </div>
      <div className="logo-absolute">SG</div>

      <div className="navbar-logo">
        <HashLink
          smooth
          to="/#landing"
          className={`logo-link ${activeLink === "landing" ? "active" : ""}`}
          onClick={() => {
            scrollToSection("landing");
            setShowNav(false);
          }}
        >
          SG
        </HashLink>
      </div>
      <ul className={`navbar-links ${showNav ? "visible" : ""}`}>
        <li>
          <HashLink
            smooth
            to="/#about"
            className={`navbar-link ${activeLink === "about" ? "active" : ""}`}
            onClick={() => {
              scrollToSection("about");
              setShowNav(false);
            }}
          >
            ABOUT
          </HashLink>
        </li>
        <li>
          <HashLink
            smooth
            to="/#skills"
            className={`navbar-link ${activeLink === "skills" ? "active" : ""}`}
            onClick={() => {
              scrollToSection("skills");
              setShowNav(false);
            }}
          >
            SKILLS
          </HashLink>
        </li>
        <li>
          <HashLink
            smooth
            to="/#work"
            className={`navbar-link ${activeLink === "work" ? "active" : ""}`}
            onClick={() => {
              scrollToSection("work");
              setShowNav(false);
            }}
          >
            WORK
          </HashLink>
        </li>
        <li>
          <HashLink
            smooth
            to="/#services"
            className={`navbar-link ${
              activeLink === "services" ? "active" : ""
            }`}
            onClick={() => {
              scrollToSection("services");
              setShowNav(false);
            }}
          >
            SERVICES
          </HashLink>
        </li>
        <li>
          <HashLink
            smooth
            to="/#contact"
            className={`navbar-link ${
              activeLink === "contact" ? "active" : ""
            }`}
            onClick={() => {
              scrollToSection("contact");
              setShowNav(false);
            }}
          >
            CONTACT
          </HashLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
